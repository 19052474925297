import React, { useState } from 'react';
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import './HeaderSection.scss';
import { MdOutlineMailOutline } from 'react-icons/md';
import { IoLogoFacebook } from 'react-icons/io';
import Logo from '../../images/Logo.png';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

const HeaderSection = () => {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);
    const token = localStorage.getItem('Userlogintoken');

    const handleLogout = () => {
        localStorage.removeItem('Userlogintoken');
        message.success('You have successfully logged out!');
        navigate('/');
        setExpanded(false); // Collapse the navbar after logout
    };

    const handleNavItemClick = (path) => {
        navigate(path);
        setExpanded(false); // Collapse the navbar after navigation
    };
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    // const handleRediration=()=>{
    //     window.open("https://www.facebook.com/TheLearningExpressAR", "_blank");
    // }
    return (
        <div className='HeaderSection'>
            <div className='section1'>
                <Navbar className="bg-body-tertiary">
                    <Container>
                        <Navbar.Brand href="#home" className='heading'>
                            <MdOutlineMailOutline />
                            <a href="mailto:Info@TheLearningExpressAR.org" className='link'>Info@TheLearningExpressAR.org</a>
                        </Navbar.Brand>
                        <Navbar.Toggle />
                        <Navbar.Collapse className="justify-content-end">
                            <Navbar.Text>
                                <div className='social_media_icon'>
                                    <div>
                                        <IoLogoFacebook
                                            size={20}
                                            onClick={() => openInNewTab("https://www.facebook.com/TheLearningExpressAR")}
                                            className='svg'
                                        />
                                    </div>
                                    {/* <div>
                                        <FaTwitter size={20} />
                                    </div>
                                    <div>
                                        <FaLinkedin size={20} />
                                    </div> */}
                                </div>
                            </Navbar.Text>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
            <div className='section2'>
                <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" expanded={expanded}>
                    <Container>
                        <Navbar.Brand onClick={() => handleNavItemClick('/')}>
                            <img src={Logo} alt='Logo' width={100} className='logo' />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
                        <Navbar.Collapse id="responsive-navbar-nav rightitem">
                            <Nav onSelect={() => setExpanded(false)}>
                                <NavDropdown title="Calculators" id="nav-dropdown">
                                    <NavDropdown.Item onClick={() => handleNavItemClick('/saving-calculator')}>Savings Calculator</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleNavItemClick('/mortgage-calculator')}>Mortgage Calculator</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleNavItemClick('/budget-calculator')}>Budget Calculator</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleNavItemClick('/debt-calculator')}>Debt Calculator</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link onClick={() => handleNavItemClick('/course')}>Courses</Nav.Link>
                                <Nav.Link onClick={() => handleNavItemClick('/forum')}>Forum</Nav.Link>
                                {token && (
                                    <NavDropdown title="Account" id="nav-dropdown">
                                        <NavDropdown.Item onClick={() => handleNavItemClick('/profile-edit')}>Profile</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => handleNavItemClick('/calculation-history')}>Calculation History</NavDropdown.Item>
                                        <NavDropdown.Item onClick={handleLogout}>LogOut</NavDropdown.Item>
                                    </NavDropdown>
                                )}
                                {!token && (
                                    <>
                                        <Nav.Link className='button_color' onClick={() => handleNavItemClick('/login')}>Login</Nav.Link>
                                        <Nav.Link className='button_color' onClick={() => handleNavItemClick('/signup')}>Signup</Nav.Link>
                                    </>
                                )}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </div>
    );
};

export default HeaderSection;
