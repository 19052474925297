import React, { useEffect, useState } from 'react'
import { Accordion, Button, Container, ListGroup, ProgressBar } from 'react-bootstrap'
import "./CourseLearn.scss"
import CourseImage from "../../images/Courses/online-tutorials-concept_52683-37480.avif"
// import { TiTick } from "react-icons/ti";
import { FiCheck } from "react-icons/fi";
import { useParams } from 'react-router-dom';
import { DocumentUrl, ImageUrl } from '../../Common/Host';
import { IoMdDownload } from "react-icons/io";
import ReactPlayer from 'react-player';
import { markLectureAsCompletedApi } from '../../Common/Api';
import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourseById } from '../../Common/Redux/courseSlice';
import Quiz from '../Quiz/Quiz';
import Login from '../Login/Login';
import { useMediaQuery } from 'react-responsive';

const CourseLearn = () => {
    const token = localStorage.getItem("Userlogintoken")
    const { id } = useParams()
    const dispatch = useDispatch();
    const data = useSelector((state) => state.course.course);
    const loading = useSelector((state) => state.course.loading);
    const [currentLecture, setCurrentLecture] = useState(null);

    useEffect(() => {
        dispatch(fetchCourseById({ id, token }));
    }, [dispatch, id, token]);

    useEffect(() => {
        if (data && data.course_sections && data.course_sections.length > 0) {
            const firstSection = data.course_sections[0];
            if (firstSection.section_lectures && firstSection.section_lectures.length > 0) {
                setCurrentLecture(firstSection.section_lectures[0]);
            }
        }
    }, [data]);

    const handleShowLecture = (lecture) => {
        setCurrentLecture(lecture);
    }

    const handledownload = (url, file_name) => {
        const fetchedDocSource = `${url}`;
        const link = document.createElement('a');
        link.href = fetchedDocSource;
        link.download = file_name;
        link.target = "_blank"; // Set target to "_blank"
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleMarkLectureAsCompleted = () => {
        markLectureAsCompletedApi(currentLecture?.id, currentLecture?.section_id, currentLecture?.course_id, token).then((res) => {
            message.success(res?.data?.message)
            dispatch(fetchCourseById({ id, token }));
        }).catch((e) => {
            console.log(e, "error")
        })
    }

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    return (
        <>
            {token !== null ?
                <div className='CourseLearn'>
                    <Container>
                        {!isMobile ?
                            <div className='desktop'>
                                <div className='main'>
                                    <div className='course_list'>
                                        <h3>{data?.name}</h3>
                                        <div className='List_scroll'>
                                            <Accordion>
                                                {data?.course_sections?.map((item, index) => (
                                                    <Accordion.Item eventKey={index} key={index}>
                                                        <Accordion.Header>
                                                            <div className='width_full'>
                                                                <div className='section_name'>
                                                                    <h5>section {index + 1}</h5>
                                                                    {item?.lecture_progress}
                                                                </div>
                                                                <h6>{item?.name}</h6>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <ListGroup>
                                                                {item?.section_lectures?.map((item1, index) => (
                                                                    <ListGroup.Item key={index}>
                                                                        <div className='section_name'>
                                                                            <div>{item1.name}</div>
                                                                            <div>
                                                                                {item1?.lecture_progress[0]?.lecture_status === "COMPLETED" &&
                                                                                    <h5 className='marked_green'><FiCheck /></h5>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className='section_name'>
                                                                            <div>
                                                                                {item1?.lecture_attachment[0]?.title === "Downloadable Resources" ? 
                                                                                    <div style={{ cursor: "pointer" }} onClick={() => handledownload(`${DocumentUrl}${item1?.lecture_attachment[0]?.file_name}`, 'downloaded_document.pdf')}>
                                                                                        <IoMdDownload color='#FAC04D' />
                                                                                        <small>Download Material</small>
                                                                                    </div> 
                                                                                    : null
                                                                                }
                                                                            </div>
                                                                            <div onClick={() => handleShowLecture(item1)} className='start'>
                                                                                Start
                                                                            </div>
                                                                        </div>
                                                                    </ListGroup.Item>
                                                                ))}
                                                            </ListGroup>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                ))}
                                            </Accordion>
                                        </div>
                                    </div>
                                    <div className='show_text'>
                                        <div className='course_progress'>
                                            <div className='prg'>
                                                <div className='heading'>
                                                    <h6>Course Progress</h6>
                                                    <h6 className='color' style={{ color: data?.course_watchList[0]?.course_progress >= 80 ? "green" : "unset" }}>
                                                        {data?.course_watchList.length === 0 ? "0" : data?.course_watchList[0]?.course_progress}% Complete
                                                    </h6>
                                                </div>
                                                <ProgressBar now={data?.course_watchList[0]?.course_progress} />
                                            </div>
                                        </div>
                                        <div className='playmode'>
                                            <div className="lecture-content">
                                                {currentLecture?.type === "Text" ? (
                                                    <div>
                                                        <h4>{currentLecture?.name}</h4>
                                                        <p>{currentLecture?.text_content}</p>
                                                    </div>
                                                ) : currentLecture?.type === "Video" ? (
                                                    <div>
                                                        <h4>{currentLecture?.name}</h4>
                                                        <ReactPlayer url={currentLecture?.video_link} controls={true} width="100%" height="420px" />
                                                    </div>
                                                ) : currentLecture?.type === "Quiz" ? (
                                                    <div>
                                                        <h4>{currentLecture?.name}</h4>
                                                        <Quiz lectureQuizData={currentLecture?.lecture_quiz} lectureQuizid={currentLecture?.id} />
                                                    </div>
                                                ) : (
                                                    <img src={`${ImageUrl}${data?.course_attachments[0]?.file_name}`} alt='courseImage' className='courseimage' />
                                                )}
                                            </div>
                                        </div>
                                        {currentLecture?.type !== "Quiz" && currentLecture?.lecture_progress[0]?.lecture_status !== "COMPLETED" && (
                                            <div className='button_sec'>
                                                <Button className="green" onClick={handleMarkLectureAsCompleted}>
                                                    Mark as Complete
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div> :
                            <div className='mobile'>
                                <div className='course_progress'>
                                    <div className='prg'>
                                        <div className='heading'>
                                            <h6>Course Progress</h6>
                                            <h6 className='color' style={{ color: data?.course_watchList[0]?.course_progress >= 80 ? "green" : "unset" }}>
                                                {data?.course_watchList.length === 0 ? "0" : data?.course_watchList[0]?.course_progress}% Complete
                                            </h6>
                                        </div>
                                        <ProgressBar now={data?.course_watchList[0]?.course_progress} />
                                    </div>
                                </div>
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>{data?.name}</Accordion.Header>
                                        <Accordion.Body>
                                            <div className='List_scroll'>
                                                <Accordion>
                                                    {data?.course_sections?.map((item, index) => (
                                                        <Accordion.Item eventKey={index} key={index}>
                                                            <Accordion.Header>
                                                                <div className='width_full'>
                                                                    <div className='section_name'>
                                                                        <h5>section {index + 1}</h5>
                                                                        {item?.lecture_progress}
                                                                    </div>
                                                                    <h6>{item?.name}</h6>
                                                                </div>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <ListGroup>
                                                                    {item?.section_lectures?.map((item1, index) => (
                                                                        <ListGroup.Item key={index}>
                                                                            <div className='section_name'>
                                                                                <div>{item1.name}</div>
                                                                                <div>
                                                                                    {item1?.lecture_progress[0]?.lecture_status === "COMPLETED" &&
                                                                                        <h5 className='marked_green'><FiCheck /></h5>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className='section_name'>
                                                                                <div>
                                                                                    {item1?.lecture_attachment[0]?.title === "Downloadable Resources" ? 
                                                                                        <div style={{ cursor: "pointer" }} onClick={() => handledownload(`${DocumentUrl}${item1?.lecture_attachment[0]?.file_name}`, 'downloaded_document.pdf')}>
                                                                                            <IoMdDownload color='#FAC04D' />
                                                                                            <small>Download Material</small>
                                                                                        </div> 
                                                                                        : null
                                                                                    }
                                                                                </div>
                                                                                <div onClick={() => handleShowLecture(item1)} className='start'>
                                                                                    Start
                                                                                </div>
                                                                            </div>
                                                                        </ListGroup.Item>
                                                                    ))}
                                                                </ListGroup>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    ))}
                                                </Accordion>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <div className='show_text'>
                                    <div className='playmode'>
                                        <div className="lecture-content">
                                            {currentLecture?.type === "Text" ? (
                                                <div>
                                                    <h4>{currentLecture?.name}</h4>
                                                    <p>{currentLecture?.text_content}</p>
                                                </div>
                                            ) : currentLecture?.type === "Video" ? (
                                                <div>
                                                    <h4>{currentLecture?.name}</h4>
                                                    <ReactPlayer url={currentLecture?.video_link} controls={true} width="100%" height="420px" />
                                                </div>
                                            ) : currentLecture?.type === "Quiz" ? (
                                                <div>
                                                    <h4>{currentLecture?.name}</h4>
                                                    <Quiz lectureQuizData={currentLecture?.lecture_quiz} lectureQuizid={currentLecture?.id} />
                                                </div>
                                            ) : (
                                                <img src={`${ImageUrl}${data?.course_attachments[0]?.file_name}`} alt='courseImage' className='courseimage' />
                                            )}
                                        </div>
                                    </div>
                                    {currentLecture?.type !== "Quiz" && currentLecture?.lecture_progress[0]?.lecture_status !== "COMPLETED" && (
                                        <div className='button_sec'>
                                            <Button className="green" onClick={handleMarkLectureAsCompleted}>
                                                Mark as Complete
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                    </Container>
                </div> :
                <Login />
            }
        </>
    )
}

export default CourseLearn
