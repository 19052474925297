import React, { useState } from 'react';
import "./DebtCalculator.scss";
import { Flex, Form, Input, Progress, Select, Space, Tooltip } from 'antd';
import { Button } from 'react-bootstrap';
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { CalculatorApi } from '../../Common/Api';
import { FaMinus, FaPlus } from "react-icons/fa";
const { Option } = Select;
const DebtCalculator = () => {
  const [debtCount, setDebtCount] = useState(1);
  const [showInfo, setShowInfo] = useState(false);
  const [responsedebtData, setResponseDebtDAta] = useState(null); // State to hold response data
  // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEsImlhdCI6MTcxNDk4NTk1MiwiZXhwIjoxNzE3NTc3OTUyLCJ0eXBlIjoic2Vzc2lvbiJ9.rJ-Ksf4xe0ofkqn6e2Z2seb6M3aoikFI0wNsd3rg3NM"
 const token=localStorage.getItem("Userlogintoken")
  const handleInfoClick = () => {
    setShowInfo(!showInfo);
  };
  const handleAddDebt = () => {
    setDebtCount(debtCount + 1);
  };
  const handleRemoveDebt = () => {
    setDebtCount(debtCount - 1);
  };
  const handleResult = (values) => {
    console.log(values,"test values")
    // Construct the response object
    const debtData = [...Array(debtCount)].map((_, index) => ({
      debtType: values[`debtType${index + 1}`],
      balanceOwed: parseFloat(values[`balance${index + 1}`]),
      interestRate: parseFloat(values[`rateOfInterest${index + 1}`]),
      minimumPayment: parseFloat(values[`minimumPayment${index + 1}`])
    }));
    const response = {
      calculatorType: "DEBT",
      debt: debtData,
      // additionalPayment: parseFloat(values.additionalPayment)
    };
    CalculatorApi(response, token)
      .then(response => {
        console.log(response,"==>>res")
        setResponseDebtDAta(response.data.calculatorOutput)
        window.scrollTo(0, 0)
      })
      .catch(error => {
        console.error('Cal Error--', error);
      });
  };
  return (
    <div className='DebtCalculator'>
      <h2>Debt Calculator</h2>
      <div className='DebtCalculator_Main'>
        <div className='DebtCalculator_Calculation'>
          <Form
            name="basic"
            layout='vertical'
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
            onFinish={handleResult}
          >
            {[...Array(debtCount)].map((_, index) => (
              <div key={index}>
                <h5>Debt {index + 1}</h5>
                <Space>
                  <Form.Item
                    label="Debt Type"
                    name={`debtType${index + 1}`}
                    rules={[{ required: true, message: 'Please select Debt Type!' }]}
                  >
                    <Select >
                      <Option value="Car Loan">Car Loan</Option>
                      <Option value="Credit Card">Credit Card</Option>
                      <Option value="Student Loan">Student Loan</Option>
                      <Option value="Other Non-Mortgage Debt">Other Non-Mortgage Debt</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Balance"
                    name={`balance${index + 1}`}
                    rules={[{ required: true, message: 'Please enter Balance!' }]}
                  >
                    <Input className='inputStyle' />
                  </Form.Item>
                </Space>
                <Space>
                  <Form.Item
                    label="Rate of Interest"
                    name={`rateOfInterest${index + 1}`}
                    rules={[{ required: true, message: 'Please enter Rate of Interest!' }]}
                  >
                    <Input className='inputStyle' />
                  </Form.Item>
                  <Form.Item
                    label="Minimum Payment"
                    name={`minimumPayment${index + 1}`}
                    rules={[{ required: true, message: 'Please enter Minimum Payment!' }]}
                  >
                    <Input className='inputStyle' />
                  </Form.Item>
                </Space>
              </div>
            ))}
            {/* <Form.Item
              label="Additional Payment"
              name="additionalPayment"
              rules={[{ required: true, message: 'Please enter Additional Payment!' }]}
            >
              <Input className='inputStyle' />
            </Form.Item> */}
            <div className='add_on_debt'>
              <Button className='add_debt_btn' onClick={handleAddDebt}>
                <FaPlus color='black' size={13} />Add Debt
              </Button>
              {debtCount >= 2 &&
                <Button className='add_debt_btn' onClick={handleRemoveDebt}>
                  <FaMinus color='black' size={13} /> Remove Debt
                </Button>
              }
            </div>
            <Form.Item>
              <Button type='primary' htmlType="submit" className='button_theme' >
                Calculate
              </Button>
            </Form.Item>
          </Form>
        </div>
        {responsedebtData &&
          <div className='DebtCalculator_Result'>
            <div className='gragh' >
              {/* <Flex gap="small" wrap>
              <Progress type="circle"
                percent={responsedebtData?.freeIn} format={(percent) => `${percent} Months`}

                style={{ fontSize: "20px" }} />
            </Flex> */}
              {/* <div className='top_heading'>
                <h5>Months to pay off</h5>
                <p className='total_saving_amount'>${responsedebtData?.freeIn}</p>
              </div> */}
              <div className='top_heading'>
                <div className='total_Saving'>
                  <h5>Months to pay off</h5>
                  <p className='total_saving_amount'>{responsedebtData?.freeIn}</p>
                </div>
              </div>
            </div>
            <div>
              <div className='total_Saving'>
                <p className='total_saving_heading'>Your Total Debt</p>
                <p className='total_saving_amount'>
                
                  {responsedebtData?.totalDebt
      ? `$${Number(responsedebtData?.totalDebt).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`
      : '$0.00'}
                  </p>
              </div>
              <div className='total_Saving'>
                <p className='total_saving_heading'>If you continue making only minimum payments, you’ll be debt-free:</p>
                <p className='total_saving_amount'>{responsedebtData?.debtFreeDateWithMinimum}</p>
              </div>
              {/* <div className='total_Saving'>
              <p className='total_saving_heading'>If you pay more each month, you’ll be debt-free by:</p>
              <p className='total_saving_amount'>{responsedebtData?.debtFreeDateWithAdditional}</p>
            </div> */}
              <hr />
              <h6>Debt BreakDown</h6>
              {responsedebtData?.debtInfo?.map((item, index) => {
                return (

                  <div className='total_Saving' key={index}>
                    <p className='total_saving_heading'>{item.debtType}</p>
                    <p className='total_saving_amount'>
                      {/* ${item?.balanceOwed} */}
                      {item?.balanceOwed
      ? `$${Number(item?.balanceOwed).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`
      : '$0.00'}
                      </p>
                  </div>
                )

              })
              }
              <div className='total_Saving'>
                <p className='total_saving_heading'>If you continue making only minimum payments, you’ll be debt-free:</p>
                <p className='total_saving_amount'>{responsedebtData?.debtFreeDateWithMinimum}</p>
              </div>
              {/* <div className='total_Saving'>
              <p className='total_saving_heading'>If you pay more each month, you’ll be debt-free by:</p>
              <p className='total_saving_amount'>{responsedebtData?.debtFreeDateWithAdditional}</p>
            </div> */}
              {/* <div>
                <h5>Total Savings Breakdown</h5>
                <div className='values'>
                  <div className='first_color'>
                  </div>
                  <div>
                    <p className='vul'>Principal & Interest</p>
                    <p className='amt'>${savingData?.principalAndInterest}</p>
                  </div>
                  <div className='second_color'>
                  </div>
                  <div>
                    <p className='vul'>Property Tax</p>
                    <p className='amt'>${savingData?.propertyTaxPerMonth}</p>
                  </div>
                  <div className='third_color'>
                  </div>
                  <div>
                    <p className='vul'>Homeowner’s Insurance</p>
                    <p className='amt'>${savingData?.homeownersInsurancePerMonth}</p>
                  </div>
                  <div className='fourth_color'>
                  </div>
                  <div>
                    <p className='vul'>HOA Fees Per Month</p>
                    <p className='amt'>${savingData?.hoaFeesPerMonth}</p>
                  </div>
                  <div className='fifth_color'>
                  </div>
                  <div>
                    <p className='vul'>PMI Per Month</p>
                    <p className='amt'>${savingData?.pmiPerMonth}</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        }
      </div>
    </div>
  );
};
export default DebtCalculator;
