import React, { useState } from 'react';
import SignupImage from "../../images/Signup.jpg";
import { Form, Input, message } from 'antd';
import { Button, Container } from 'react-bootstrap';
import "./Signup.scss";
import { SendEmailApi, VerifyOtpAPI, createUserAPI } from '../../Common/Api';
import { useNavigate } from 'react-router-dom';
const Signup = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerify, setOtpVerify] = useState(false);
  const [showAccountFields, setShowAccountFields] = useState(false);
  const onFinish = async (values) => {
    if (otpVerify) {
      createUserAPI(values?.name, email, values?.mobile, values?.password, values?.confirmPassword)
        .then((res) => {
          message.success(res?.data?.data?.message)
          navigate("/login")
        })
        .catch((error) => {
          console.log(error, "SignupError");
        });
    }
    if (otpSent) {
      // Verify OTP
      const { otp } = values;
      try {
        await VerifyOtpAPI(email, otp);
        message.success('OTP verified successfully');
        setOtpVerify(true);
        setOtpSent(false);
        setShowAccountFields(true);
      } catch (error) {
        console.error('Failed to verify OTP:', error);
      }
    } else {
      // Send OTP
      try {
        await SendEmailApi(values.useremail, "Register");
        setEmail(values.useremail);
        setOtpSent(true);
        message.success('OTP sent to your email. Please check and verify.');
      } catch (error) {
        console.error('Failed to send OTP:', error);
      }
    }
  };
  const createUser = async (values) => {
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const handleRedirection = (path) => {
    navigate(`/${path}`)
  }
  return (
    <div className='Signup'>
      <Container>
        <div className='SignupMain'>
          <div className='left'>
            <img src={SignupImage} alt="" />
          </div>
          <div className='right'>
            <h1>Sign Up</h1>
            <Form
              name="basic"
              layout='vertical'
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className='RightForm'
            >
              {!otpSent && !otpVerify && (
                <Form.Item
                  label="Email"
                  name="useremail"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please input a valid email!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
              {otpSent && !showAccountFields && (
                <Form.Item
                  label="OTP"
                  name="otp"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the OTP!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
              {showAccountFields && (
                <>
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your name!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Mobile Number"
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your mobile number!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    label="Confirm Password"
                    name="confirmPassword"
                    dependencies={['password']}
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The two passwords do not match!'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </>
              )}
              <Form.Item>
                <Button type="primary" htmlType="submit" className='button_theme'>
                  {otpSent ? (showAccountFields ? 'Create Account' : 'Verify OTP') : 'Send OTP'}
                </Button>
              </Form.Item>
            </Form>
            <div className='SignupText'>
              <p>Already have an account? <span onClick={() => handleRedirection("login")} >Login</span></p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default Signup;
