import React, { useState } from "react";
import "./SavingCalculator.scss";
import { Form, Input, Radio, Space, Tooltip } from "antd";
import { BsFillQuestionCircleFill } from "react-icons/bs";

import { CalculatorApi } from "../../Common/Api";
import { Button } from "react-bootstrap";
const SavingCalculator = () => {
  const token = localStorage.getItem("Userlogintoken");
  const [savingData, setSavingData] = useState();
  const [showInfo, setShowInfo] = useState(false);
  const handleInfoClick = () => {
    setShowInfo(!showInfo);
  };
  const handleResult = (values) => {
    const requestData = {
      calculatorType: "SAVINGS", //enum
      user_input: {
        initialDeposit: values.initialDeposit, //double
        monthlyContribution: values?.monthlyContribution, //double
        period: values.period, //integer
        durationUnit: values.in, //enum
        apy: values.apy, //double
      },
    };
    CalculatorApi(requestData, token)
      .then((response) => {
        console.log(response.data?.calculatorOutput);
        setSavingData(response.data?.calculatorOutput);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        console.error("Cal Error--", error);
      });
  };
  return (
    <div className="SavingCalculator">
      <h2>Savings Calculator</h2>
      <div className="SavingCalculator_Main">
        <div className="SavingCalculator_Calculation">
          <Form
            name="basic"
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
            onFinish={handleResult}
          >
            <Space>
              <Form.Item
                label={
                  <>
                    <span>Initial deposit </span>
                    <Tooltip title="Initial deposit is the minimum amount of money required to open an account with a financial institution.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                name="initialDeposit"
                rules={[
                  {
                    required: true,
                    message: "Please enter Initial deposit!",
                  },
                ]}
              >
                <Input className="inputStyle" />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>Monthly contribution </span>
                    <Tooltip title="Monthly Contribution means the monthly Regular Contribution or Additional Regular Contribution, or the average monthly figure of the Regular Contribution or Additional Regular Contribution">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                name="monthlyContribution"
                rules={[
                  {
                    required: true,
                    message: "Please enter Monthly contribution!",
                  },
                ]}
              >
                <Input className="inputStyle" />
              </Form.Item>
            </Space>
            <Form.Item
              label={
                <>
                  <span>Over a period of </span>
                  <Tooltip title="It happened over a period of time. The planning took place over a period of time. Frustration has been building up over a period of time. That's a skill that comes over a period of time.">
                    <BsFillQuestionCircleFill
                      onClick={handleInfoClick}
                      className="icon_info"
                    />
                  </Tooltip>
                </>
              }
              name="period"
              rules={[
                {
                  required: true,
                  message: "Please enter Over a period!",
                },
              ]}
            >
              <Input className="inputStyle" />
            </Form.Item>
            <Form.Item
              label={
                <Space>
                  <span>In</span>
                </Space>
              }
              name="in"
              rules={[
                {
                  required: true,
                  message: "Please enter duration!",
                },
              ]}
            >
              <Radio.Group>
                <Radio value="years">Years</Radio>
                <Radio value="months">Months</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={
                <>
                  <span>APY </span>
                  <Tooltip title="The annual percentage yield (APY) is the real rate of return earned on an investment, taking into account the effect of compounding interest. Unlike simple interest, compounding interest is calculated periodically and the amount is immediately added to the balance.">
                    <BsFillQuestionCircleFill
                      onClick={handleInfoClick}
                      className="icon_info"
                    />
                  </Tooltip>
                </>
              }
              name="apy"
              rules={[
                {
                  required: true,
                  message: "Please enter APY!",
                },
              ]}
            >
              <>
                <Input className="inputStyle" />
              </>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="button_theme">
                Calculate
              </Button>
            </Form.Item>
          </Form>
        </div>
        {savingData && (
          <div className="SavingCalculator_Result">
            <div>
              <div className="total_Saving">
                <p className="total_saving_heading">Your Total Savings</p>
                <p className="total_saving_amount">
                  {/* ${savingData?.totalSavings} */}
                  {savingData?.totalSavings
                    ? `$${Number(savingData.totalSavings).toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}`
                    : "$0.00"}
                </p>
              </div>
              <div>
                <h5>Total Savings Breakdown</h5>
                <div className="values">
                  <div className="first_color"></div>
                  <div>
                    <p className="vul">Initial Deposit</p>
                    <p className="amt">
                      {/* ${savingData?.initialDeposit} */}
                      {savingData?.initialDeposit
                        ? `$${Number(savingData.initialDeposit).toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}`
                        : "$0.00"}
                    </p>
                  </div>
                  <div className="second_color"></div>
                  <div>
                    <p className="vul">Total Contribution</p>
                    <p className="amt">
                      {" "}
                      {savingData?.totalContribution
                        ? `$${Number(
                            savingData.totalContribution
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : "$0.00"}
                    </p>
                  </div>
                  <div className="third_color"></div>
                  <div>
                    <p className="vul">Interest Earned</p>
                    <p className="amt">
                      {" "}
                      {savingData?.interestEarned
                        ? `$${Number(savingData.interestEarned).toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}`
                        : "$0.00"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default SavingCalculator;
