import React, { useState } from "react";
import "./ForgotPasswordForm.scss";
// import loginImg from "../../assest/login.png";
import { Form, Input, message } from "antd";
import { RestPassword, SendEmailApi, VerifyOtpAPI } from "../../Common/Api";
import { Button, Container } from "react-bootstrap";
import logo from "../../images/Banner.png";
import { useNavigate } from "react-router-dom";
const ForgotPasswordForm = () => {
    const naviagte=useNavigate()
    const [emailSent, setEmailSent] = useState(false);
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [currentStep, setCurrentStep] = useState(0);
    const handleSendEmail = (values) => {
        SendEmailApi(values.email, "forget_password")
            .then((res) => {
                message.success('OTP sent to your email. Please check and verify.');
                setEmailSent(true); // Set email sent to true
                setEmail(values.email); // Save the email
                setCurrentStep(1);
            })
            .catch((e) => {
                console.log(e)
            });
    };
    const handleResetPassword = (values) => {
        const formData = {
            email_id: email,
            otp: values.otp,
            password: values.password,
            confirm_password: values.confirm_password,
        };
        RestPassword(formData)
            .then((res) => {
                setCurrentStep(3);
                naviagte("/login")
            })
            .catch((e) => {
                console.log(e)
            });
    };
    return (
        <div className='Login'>
            <Container>
                <div className='loginMain'>
                    <div className='left'>
                        <img src={logo} alt="" />
                    </div>
                    <div className='right'>
                        <h1>Forgot Your Password?</h1>
                        <p className="para">
                            Don't worry, we'll help you get back into your account. Enter your email address below to reset your password.
                        </p>
                        <div className="form">
                            {currentStep === 0 && (
                                <Form onFinish={handleSendEmail} layout="vertical">
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        rules={[{ type: "email", required: true }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            style={{ backgroundColor: "#25B8CB" }}
                                            type='primary' 
                                            htmlType="submit"
                                            block
                                            disabled={emailSent}
                                        >
                                            Send Email
                                        </Button>
                                    </Form.Item>
                                </Form>
                            )}
                            {/* {currentStep === 1 && (
                                <Form onFinish={handleVerifyOTP} layout="vertical">
                                    <Form.Item name="otp" label="OTP" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            style={{ backgroundColor: "#25B8CB" }}
                                            type="primary"
                                            htmlType="submit"
                                            block
                                        >
                                            Verify OTP
                                        </Button>
                                    </Form.Item>
                                </Form>
                            )} */}
                            {currentStep === 1 && (
                                <Form onFinish={handleResetPassword} layout="vertical">
                                    <Form.Item name="otp" label="OTP" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        label="Password"
                                        rules={[{ required: true }]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                    <Form.Item
                                        name="confirm_password"
                                        label="Confirm Password"
                                        dependencies={["password"]}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please confirm your password!",
                                                validator: (_, value) =>
                                                    value
                                                        ? Promise.resolve()
                                                        : Promise.reject("Please confirm your password!"),
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue("password") === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        "The two passwords that you entered do not match!"
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            style={{ backgroundColor: "#25B8CB" }}
                                            type="primary"
                                            htmlType="submit"
                                            block
                                        >
                                            Reset Password
                                        </Button>
                                    </Form.Item>
                                </Form>
                            )}
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};
export default ForgotPasswordForm;
