import React, { useEffect, useState } from 'react'
import "./Course.scss"
import { Container } from 'react-bootstrap'
import { GetAllCourseAPI } from '../../Common/Api'
import { Rate, Skeleton } from 'antd'
import { ImageUrl } from '../../Common/Host'
import { useNavigate } from 'react-router-dom'
const Course = () => {
    const navigate = useNavigate()
    const [course, setCourses] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        GetAllCourseAPI().then((res) => {
            console.log(res,"res==>>")
            setCourses(res.data.data)
            setLoading(false)
        }).catch((e) => {
            console.log(e, "Error---")
            setLoading(false)
        })
    }, [])
    return (
        <div className='Course'>
            <Container>
                <h2>Courses</h2>
                <div className="row row-cols-1 row-cols-md-4 g-4">
                    {(loading || course.length === 0) ? (
                        // Show skeleton image while loading or if course array is empty
                        <>
                            {[...Array(4)].map((_, index) => (
                                <div className="col" key={index}>
                                    <Skeleton.Image className="card-img-top" />
                                    <div className="card-body">
                                        <Skeleton.Input style={{ width: "100%", marginBottom: 10 }} active />
                                        <Skeleton.Input style={{ width: "100%", marginBottom: 10 }} active />
                                        <Skeleton.Input style={{ width: "100%", marginBottom: 10 }} active />
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : (
                        // Render course cards when courses are available
                        <>
                            {course.map((item) => (
                                <div className="col" key={item.id}>
                                    <div className="card h-100">
                                        <img src={item?.course_attachments[0]?.file_name === null ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWMSHN7TiyEIBE9IITKMxFlFAux7Xy0nQG_A&usqp=CAU" : `${ImageUrl}${item?.course_attachments[0]?.file_name}`} className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <h5 className="card-title">{item?.name}</h5>
                                            <Rate disabled defaultValue={item?.rating} />
                                            <button className='start_btn' onClick={() => navigate(`/coursedetails/${item.id}`)}>Start Learning</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </Container>
        </div>
    )
}
export default Course
