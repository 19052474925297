import { useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
export default function DescriptionAlerts({ text, icon, time }) {
  const navigate = useNavigate()
  useEffect(() => {
    const handleClose = () => {
      Swal.close();
    };

    Swal.fire({
      position: 'top-center',
      icon: icon,
      title: text,
      showConfirmButton: false,
      timer: time
    })
  }, [text, icon, time]);

  return null;
}
