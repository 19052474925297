import { createSlice } from '@reduxjs/toolkit';
import { GetAllQuestion, GetProfileTokenAPI } from '../../Common/Api';

const initialState = {
  allQuestions: [],
  filterQuestion: null,
  userDetail: null,
  alert: false,
  alertConfig: {
    text: '',
    icon: '',
  },
  loading: false,
  error: null,
};

const forumSlice = createSlice({
  name: 'forum',
  initialState,
  reducers: {
    setAllQuestions(state, action) {
      state.allQuestions = action.payload;
    },
    setFilterQuestion(state, action) {
      state.filterQuestion = action.payload;
    },
    setUserDetail(state, action) {
      state.userDetail = action.payload;
    },
    setAlert(state, action) {
      state.alert = action.payload;
    },
    setAlertConfig(state, action) {
      state.alertConfig = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  setAllQuestions,
  setFilterQuestion,
  setUserDetail,
  setAlert,
  setAlertConfig,
  setLoading,
  setError,
} = forumSlice.actions;

export const fetchAllQuestions = (token) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await GetAllQuestion(token);
    dispatch(setAllQuestions(response.data.data));
  } catch (error) {
    dispatch(setError(error.message));
  }
  dispatch(setLoading(false));
};

export const fetchUserDetail = (token) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await GetProfileTokenAPI(token);
    dispatch(setUserDetail(response.data.data));
  } catch (error) {
    dispatch(setError(error.message));
  }
  dispatch(setLoading(false));
};

// Similarly, define async actions for other API calls

export default forumSlice.reducer;
