import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Forum.scss";
import { Button, Container } from "react-bootstrap";
import { Card, Input, message } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ImageUrl } from "../../Common/Host";
import { fetchUserProfile } from "../../Common/Redux/UserSlice";
import {
  fetchAllUserQuestions,
  sendQuestion,
} from "../../Common/Redux/QuestionSlice";
import { fetchAllQuestions } from "../../Common/Redux/forumSlice";
import AskQuestion from "../../images/askques.gif";
const Forum = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [question, setQuestion] = useState("");
  const token = localStorage.getItem("Userlogintoken");

  const forum = useSelector((state) => state?.forum?.allQuestions);
  console.log(forum, "forum");
  const { profile } = useSelector((state) => state.user);

  const handleAskQuestion = () => {
    if (token) {
      dispatch(sendQuestion({ question, token }))
        .then((res) => {
          message.success(res.payload.message);
          dispatch(fetchAllQuestions(token));

          setQuestion("");
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    if (token) {
      dispatch(fetchAllQuestions(token));
      dispatch(fetchUserProfile(token));
    } else {
      dispatch(fetchAllUserQuestions());
    }
  }, [dispatch, token]);

  return (
    <div className="Forum">
      <Container>
        <div className="top">
          <div>
            <h3>Forum</h3>
          </div>
          {!token && (
            <div>
              <Button className="button_theme" onClick={handleAskQuestion}>
                Ask Question
              </Button>
            </div>
          )}
        </div>
        <div className="forum_main">
          <div className="Input_box">
            {token && (
              <Card>
                <div className="main_card">
                  <div className="user_image">
                    {profile?.user_attachment?.[0]?.file_name ? (
                      <img
                        src={`${ImageUrl}${profile.user_attachment[0].file_name}`}
                        alt="User"
                      />
                    ) : (
                      <img
                        src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
                        alt="Default User"
                      />
                    )}
                  </div>
                  <div>
                    <Input
                      placeholder="Ask a question"
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                    />
                    <Button
                      className="button_theme"
                      onClick={handleAskQuestion}
                      disabled={!question}
                    >
                      Ask Question
                    </Button>
                  </div>
                </div>
              </Card>
            )}
            <div style={{ textAlign: "center" }}>
              {forum.length === 0 && <img src={AskQuestion} alt="" />}
            </div>
            {forum
              ?.slice()
              ?.reverse()
              ?.map((item, index) => (
                <Card key={index}>
                  <div>
                    <div className="main_card">
                      <div className="user_image">
                        {item?.user_forum?.[0]?.user_attachment?.[0]
                          ?.file_name ? (
                          <img
                            src={`${ImageUrl}${item.user_forum[0].user_attachment[0].file_name}`}
                            alt="User"
                          />
                        ) : (
                          <img
                            src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
                            alt="Default User"
                          />
                        )}
                      </div>
                      <div>
                        <h6>{item.user_forum[0]?.name}</h6>
                      </div>
                    </div>
                    <div className="question_section">
                      <div className="question">
                        <h6
                          dangerouslySetInnerHTML={{ __html: item.question }}
                        ></h6>
                        <h6>{moment(item.created_at).fromNow()}</h6>
                      </div>
                      {item.answer === null ? (
                        <p className="adminres">
                          Please wait for an admin to respond.
                        </p>
                      ) : (
                        <p
                          dangerouslySetInnerHTML={{ __html: item.answer }}
                        ></p>
                      )}
                    </div>
                  </div>
                </Card>
              ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Forum;
