import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetAllQuestion, GetAllUserQuestion, SendQuestionsApi } from '../../Common/Api';

export const fetchAllQuestions = createAsyncThunk('questions/fetchAll', async (token) => {
  const response = await GetAllQuestion(token);
  return response.data.data;
});

export const fetchAllUserQuestions = createAsyncThunk('questions/fetchAllUser', async () => {
  const response = await GetAllUserQuestion();
  return response.data.data;
});

export const sendQuestion = createAsyncThunk('questions/send', async ({ question, token }) => {
  const response = await SendQuestionsApi(question, token);
  return response.data;
});

const questionsSlice = createSlice({
  name: 'questions',
  initialState: {
    questions: [],
    status: 'idle',
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllQuestions.fulfilled, (state, action) => {
        state.questions = action.payload;
        state.status = 'succeeded';
      })
      .addCase(fetchAllUserQuestions.fulfilled, (state, action) => {
        state.questions = action.payload;
        state.status = 'succeeded';
      })
      .addCase(sendQuestion.fulfilled, (state, action) => {
        state.questions.push(action.payload);
      });
  },
});

export default questionsSlice.reducer;
