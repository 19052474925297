import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input,message, Space } from 'antd';
import "./ProfileComponent.scss";
import Login from '../Login/Login';
import { GetProfileTokenAPI, UpdateProfileAPI } from '../../Common/Api';
import { Container,Button } from 'react-bootstrap';
import { ImageUrl } from '../../Common/Host';
const ProfileComponent = () => {
    const [form] = Form.useForm();
    const [user, setUser] = useState();
    const [selectedImage, setSelectedImage] = useState("");
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const token = localStorage.getItem("Userlogintoken");
    const getProfileToken = useCallback(() => {
        if (token) {
            GetProfileTokenAPI(token)
                .then((res) => {
                    setUser(res?.data?.data);
                    form.setFieldValue("name", res?.data?.data?.name);
                    form.setFieldValue("mobile", res?.data?.data?.mobile_number);
                    form.setFieldValue("email", res?.data?.data?.email_id);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [token, form]);
    useEffect(() => {
        getProfileToken();
    }, [token, getProfileToken]);
    const onFinish = (value) => {
        UpdateProfileAPI(value?.name, value?.mobile, selectedImage, token)
            .then((res) => {
                message.success(res?.data?.message);
                getProfileToken(); // Update the user data after successful update
            })
            .catch((error) => {
                console.log(error);
            });
    };
    return (
        <>
            {token !== null ? (
                <div className='ProfileComponent'>
                    <Container>
                        <h1>Profile</h1>
                        <div className="Profile_main_component">
                            <Form onFinish={onFinish} layout="vertical"
                                form={form}
                            >
                                {/* <div className="profiletop">
                                    {user?.profile_picture ? (
                                        <img
                                            src={`${ImageUrl}${user.profile_picture}`}
                                            alt="Profile Pic"
                                            className='Profile_top_images'
                                            style={{ borderRadius: "50px" }}
                                        />
                                    ) : (
                                        <img
                                            src='https://img.freepik.com/premium-vector/anonymous-user-circle-icon-vector-illustration-flat-style-with-long-shadow_520826-1931.jpg?w=360'
                                            alt=""
                                            className='Profile_top_images'
                                            style={{ borderRadius: "100px" }}
                                        />
                                    )}
                                    <label className="custom_file_upload">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            name="image"
                                            onChange={(e) => {
                                                const file = e.target.files[0];
                                                setSelectedImage(file);
                                            }}
                                        />
                                        <span style={{ cursor: "pointer" }}>
                                            &nbsp; {user?.profile_picture ? "Change" : "Add"} Profile picture
                                        </span>
                                    </label>
                                </div> */}
                                <div className="profile-picture-container">
                                    {selectedImage ? (
                                        <img
                                            src={URL.createObjectURL(selectedImage)}
                                            alt="Profile_top_images"
                                            className="Profile_top_images"
                                        />
                                    ) : user?.user_attachment[0]?.file_name ? (
                                        <img
                                            src={`${ImageUrl}${user?.user_attachment[0]?.file_name}`}
                                            alt="Profile Pic"
                                            className="Profile_top_images"
                                            style={{ borderRadius: "50px" }}
                                        />
                                    ) : (
                                        <img
                                            src="https://img.freepik.com/premium-vector/anonymous-user-circle-icon-vector-illustration-flat-style-with-long-shadow_520826-1931.jpg?w=360"
                                            alt=""
                                            className="Profile_top_images"
                                            style={{ borderRadius: "100px" }}
                                        />
                                    )}
                                    <label className="custom_file_upload">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            name="image"
                                            onChange={(e) => {
                                                const file = e.target.files[0];
                                                setSelectedImage(file);
                                            }}
                                        />
                                        <span style={{ cursor: "pointer" }}>
                                            &nbsp; {selectedImage||user?.user_attachment[0]?.file_name ? "Change" : "Add"} Profile picture
                                        </span>
                                    </label>
                                </div>
                                <Space>
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                    >
                                        <Input value={name} onChange={(e) => setName(e.target.value)} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                    >
                                        <Input value={user?.email_id} disabled />
                                    </Form.Item>
                                </Space>
                                <Form.Item
                                    label="Mobile Number"
                                    name="mobile"
                                >
                                    {/* <Input type="number" value={mobile} onChange={(e) => setMobile(e.target.value)} /> */}
                                    <Input
                                        value={mobile}
                                        onChange={(e) => setMobile(e.target.value)}
                                        placeholder={user?.mobile_number ? user.mobile_number : 'Enter your mobile number'}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <div className='bUtton_custom'>
                                        <Button type="primary" htmlType="submit" className='button_theme'>Save</Button>
                                    </div>
                                </Form.Item>
                            </Form>
                        </div>
                    </Container>
                </div>
            ) : (
                <Login />
            )}
        </>
    );
};
export default ProfileComponent;
