import React, { useEffect, useState } from 'react'
import { Accordion, Button, Container, ListGroup } from 'react-bootstrap'
import "./CourseDetails.scss"
import StudentReview from './StudentReview'
import { GetCourseById, GetCourseByIdWithoutToken, addCourseToWatchListApi } from '../../Common/Api'
import { useNavigate, useParams } from 'react-router-dom'
import { ImageUrl } from '../../Common/Host'
import { Rate, Skeleton } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCourseById } from '../../Common/Redux/courseSlice'
const CourseDetails = () => {
  const token = localStorage.getItem("Userlogintoken")
  const [course, setCourses] = useState()
  const { id } = useParams()
  const naviagte = useNavigate()
  const handleRedirectLearn = () => {
    if (token) {
      addCourseToWatchListApi(id, token).then((res) => {
        naviagte(`/coursesection/${id}`)
      }).catch((e) => {
        console.log(e, "error")
      })
    }
    else (
      naviagte(`/login`)
    )
  }
  const dispatch = useDispatch();
  // const course = useSelector((state) => state.course.course);
  useEffect(() => {
    if (token) {
      GetCourseById(id, token).then((res) => {
        setCourses(res.data.data)
      }).catch((e) => {
        console.log(e)
      })
    }
    else {
      GetCourseByIdWithoutToken(id).then((res) => {
        setCourses(res.data.data)
      }).catch((e) => {
        console.log(e)
      })
    }
  }, [id])
  const loading = useSelector((state) => state.course.loading);
  useEffect(() => {
    dispatch(fetchCourseById({ id, token }));
  }, [dispatch, id, token]);
  const handleClickGetCertificate = () => {
    if (token) {
      if (course?.course_watchList[0]?.certificate_url) {
        window.open((course?.course_watchList[0]?.certificate_url), '_blank');
      }
    }
  };
  return (<>
    <Container>
      <div className='CourseDetails'>
        <div className='section1'>
          <div className='Left'>
            {loading ? (
              Array.from({ length: 1 }, (_, index) => (
                <div key={index} >
                  <Skeleton.Image active={true} className='courseimage' />
                </div>
              ))
            ) :
              <img src={`${ImageUrl}${course?.course_attachments[0]?.file_name}`} alt='courseImage' className='courseimage' />}
          </div>
          <div className='Right'>
            <div className='heading'>
              <h3>{course?.name}</h3>
            </div>
            <div className='heading'>
              <Rate disabled defaultValue={course?.rating} />
              <span>({course?.rating_in_numbers === null ? 0 : course?.rating_in_numbers}) ratings</span>
            </div>
            <div className='heading'>
              <h6>Author Name: {course?.author_name}</h6>
            </div>
            <div className='Butthon_section'>
              <Button className='button_theme' onClick={handleRedirectLearn}>Start Learning</Button>
              {/* <Button className='button_theme' disabled>Get Certificate</Button> */}
              {token ?
                <Button
                  className='button_theme'
                  disabled={course?.course_watchList[0]?.is_certificate_provided === false}
                  onClick={handleClickGetCertificate}
                >
                  Get Certificate
                </Button> : <Button className='button_theme' disabled>Get Certificate</Button>
              }
            </div>
          </div>
        </div>
        <div className='section2'>
          {loading ? <Skeleton active /> : <>
            <h5>Description</h5>
            <p dangerouslySetInnerHTML={{ __html: course?.description }} ></p>
          </>}
        </div>
        <div className='section3'>
          <Accordion>
            {course?.course_sections?.map((item, index) => {
              return (
                <Accordion.Item eventKey={index} key={index}>
                  <Accordion.Header>
                    <div>
                      <small>Section {index + 1}</small>
                      <h6>{item?.name}</h6>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ListGroup >
                      {item?.section_lectures?.map((item1, index) => {
                        return (
                          <ListGroup.Item>{item1.name}</ListGroup.Item>
                        )
                      })}
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              )
            })}
          </Accordion>
        </div>
        
          <div className='section4'>
            <StudentReview courseId={id} reviewcheck={course?.student_review} />
          </div>
      
      </div>
    </Container>
  </>
  )
}
export default CourseDetails
