import React, { useEffect, useState } from "react";
import "./CalculatorHistory.scss";
import AllCalculator from "../../images/Banner.png";

import SavingCalculator from "../../images/landing/Savings Calculator.jpg";
import bugetImg from "../../images/landing/bugetImg.png";
import deptImg from "../../images/landing/deptImg.png";
import mortgageImg from "../../images/landing/mortgageImg.png";
import { Container } from "react-bootstrap";
import { GetHistoryAPI } from "../../Common/Api";
const CalculatorHistory = () => {
  const token = localStorage.getItem("Userlogintoken");
  const [calculator_type, setCalculatorType] = useState("all");
  // const location = useLocation();
  const [history, setHistory] = useState({});
  // const isActive = (path) => {
  //   return location.pathname === path ? "active" : "";
  // };
  useEffect(() => {
    GetHistoryAPI(calculator_type, token)
      .then((res) => {
        setHistory(res.data.data);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  }, [calculator_type, token]);
  return (
    <div className="CalculatorHistory">
      <Container>
        <div className="calculator_main">
          <div
            className={`calculator ${
              calculator_type === "all" ? "active" : ""
            }`}
            onClick={() => setCalculatorType("all")}
          >
            <img
              src={AllCalculator}
              alt="SavingCalculator"
              className="calculatorImage"
            />
            <span>All Calculation</span>
          </div>
          <div
            className={`calculator ${
              calculator_type === "SAVINGS" ? "active" : ""
            }`}
            onClick={() => setCalculatorType("SAVINGS")}
          >
            <img
              src={SavingCalculator}
              alt="SavingCalculator"
              className="calculatorImage"
            />
            <span>Savings calculator</span>
          </div>
          <div
            className={`calculator ${
              calculator_type === "MORTGAGE" ? "active" : ""
            }`}
            onClick={() => setCalculatorType("MORTGAGE")}
          >
            <img
              src={mortgageImg}
              alt="SavingCalculator"
              className="calculatorImage"
            />
            <span>Mortgage calculator</span>
          </div>
          <div
            className={`calculator ${
              calculator_type === "DEBT" ? "active" : ""
            }`}
            onClick={() => setCalculatorType("DEBT")}
          >
            <img
              src={deptImg}
              alt="SavingCalculator"
              className="calculatorImage"
            />
            <span>Debt calculator</span>
          </div>
          <div
            className={`calculator ${
              calculator_type === "BUDGET" ? "active" : ""
            }`}
            onClick={() => setCalculatorType("BUDGET")}
          >
            <img
              src={bugetImg}
              alt="SavingCalculator"
              className="calculatorImage"
            />
            <span>Budget calculator</span>
          </div>
        </div>
        <div className="CalculatorContent">
          <h2>History</h2>
          {history &&
            Object.keys(history)?.map((item, index) => {
              return history[item]?.map((item2, index2) => {
                return (
                  <div>
                    {item2?.calculatorType === "SAVINGS" ? (
                      <>
                        <h6>SAVING CALCULATOR</h6>
                        <div className="content_background">
                          <p>Suggested Monthly Contribution</p>
                          <h6 className="price">
                            {item2?.calculatorOutput?.totalContribution
                              ? `$${Number(
                                  item2?.calculatorOutput?.totalContribution
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`
                              : "$0.00"}
                          </h6>
                        </div>
                      </>
                    ) : item2?.calculatorType === "MORTGAGE" ? (
                      <>
                        <div className="mortgage-section">
                          <h6>MORTGAGE CALCULAtOR</h6>
                          <div className="content_background">
                            <p>Suggested Monthly EMI</p>
                            <h6 className="price">
                              $ {item2?.calculatorOutput?.pmiPerMonth}
                            </h6>
                            <p>Total amount payable is</p>
                            <h6 className="price">
                              {/* $ {item2?.calculatorOutput?.totalMonthlyPayment} */}

                              {item2?.calculatorOutput?.totalMonthlyPayment
                                ? `$${Number(
                                    item2?.calculatorOutput?.totalMonthlyPayment
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`
                                : "$0.00"}
                            </h6>
                            <p>Interest component </p>
                            <h6 className="price">
                              {item2?.calculatorOutput?.principalAndInterest
                                ? `$${Number(
                                    item2?.calculatorOutput
                                      ?.principalAndInterest
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`
                                : "$0.00"}
                              {/* $ {item2?.calculatorOutput?.principalAndInterest} */}
                            </h6>
                          </div>
                        </div>
                      </>
                    ) : item2?.calculatorType === "DEBT" ? (
                      <>
                        <div className="debt-section">
                          <h6>DEBT CALCULATOR</h6>
                          <div className="content_background">
                            <p>
                              If you continue making only minimum
                              <br /> payments, you'll be debt-free:
                            </p>
                            <h6 className="price">
                              {item2?.calculatorOutput?.debtFreeDateWithMinimum}
                            </h6>
                            <p>
                              If you pay more each month, you'll be
                              <br /> debt-free by:
                            </p>
                            <h6 className="price">
                              {
                                item2?.calculatorOutput
                                  ?.debtFreeDateWithAdditional
                              }
                            </h6>
                          </div>
                        </div>
                      </>
                    ) : item2?.calculatorType === "BUDGET" ? (
                      <>
                        <div className="budget-section">
                          <h6>BUDGET CALCULAtOR</h6>
                          <div className="content_background">
                            <p>Monthly Expenses</p>
                            <h6 className="price">
                              {item2?.calculatorOutput?.monthlyExpense
                                ? `$${Number(
                                    item2?.calculatorOutput?.monthlyExpense
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`
                                : "$0.00"}
                            </h6>
                            <p>Monthly Income</p>
                            <h6 className="price">
                              {item2?.calculatorOutput?.monthlyIncome
                                ? `$${Number(
                                    item2?.calculatorOutput?.monthlyIncome
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`
                                : "$0.00"}
                            </h6>
                            <p>Net Income</p>
                            <h6 className="price">
                              {item2?.calculatorOutput?.netIncome
                                ? `$${Number(
                                    item2?.calculatorOutput?.netIncome
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`
                                : "$0.00"}
                            </h6>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              });
            })}
        </div>
      </Container>
    </div>
  );
};
export default CalculatorHistory;
