import React, { useState } from "react";
import "./MortgageCalculator.scss";
import { Form, Input, Radio, Space, Tooltip } from "antd";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { Button } from "react-bootstrap";
import { CalculatorApi } from "../../Common/Api";
import { PieChart } from "@mui/x-charts";
const MortgageCalculator = () => {
  const token = localStorage.getItem("Userlogintoken");
  const [savingData, setSavingData] = useState();
  const [showInfo, setShowInfo] = useState(false);
  const handleInfoClick = () => {
    setShowInfo(!showInfo);
  };
  const handleResult = (values) => {
    const requestData = {
      calculatorType: "MORTGAGE", //enum
      user_input: {
        homePrice: values.HomePrice, //double
        downPayment: values.DownPayment, //double
        durationOfLoan: values.Durationofloan, //integer
        rateOfInterest: values.Rateofinterest,
        propertyTaxPerMonth: values.Propertytax,
        homeownersInsurancePerMonth: values.Homeownerinsurance,
        pmiPerMonth: values.PMIper,
        hoaFeesPerMonth: values.HOAfees,
      },
    };
    console.log(requestData);
    CalculatorApi(requestData, token)
      .then((response) => {
        console.log(response, "res==>>");
        setSavingData(response?.data?.calculatorOutput);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        console.error("Cal Error--", error);
      });
  };
  return (
    <div className="MortgageCalculator">
      <h2>Mortgage Calculator</h2>
      <div className="MortgageCalculator_Main">
        <div className="MortgageCalculator_Calculation">
          <Form
            name="basic"
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
            onFinish={handleResult}
          >
            <Space>
              <Form.Item
                label={
                  <>
                    <span>Home price </span>
                    <Tooltip title="A mortgage calculator translates a home price or loan amount into the corresponding monthly payment.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                name="HomePrice"
                rules={[
                  {
                    required: true,
                    message: "Please enter home price!",
                  },
                ]}
              >
                <Input className="inputStyle" />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>Down payment </span>
                    <Tooltip title="The down payment is an initial payment for the purchase of an item on credit. In simple terms, it is an advance payment for an expensive purchase.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter Down Payment!",
                  },
                ]}
                name="DownPayment"
              >
                <Input className="inputStyle" />
              </Form.Item>
            </Space>
            <Space>
              <Form.Item
                label={
                  <>
                    <span>Rate of interest (P.A) </span>
                    <Tooltip title="A rate of interest 'per cent per annum', written as p.c.p.a., gives the amount of interest due on every hundred rupees for a period of one year, that is, annually.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter Rate of Interest",
                  },
                ]}
                name="Rateofinterest"
              >
                <Input className="inputStyle" />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>Duration of loan in Months (in years) </span>
                    <Tooltip title="The Macaulay duration of a loan is the number of years that pass from the loan draw down date until half of the time-weighted present value of the debt services has been paid.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter Duration of loan!",
                  },
                ]}
                name="Durationofloan"
              >
                <Input className="inputStyle" />
              </Form.Item>
            </Space>{" "}
            <Space>
              <Form.Item
                label={
                  <>
                    <span>Property tax per month </span>
                    <Tooltip title="A credit score is based on your credit history, which includes information like the number accounts, total levels of debt, repayment history, and other factors.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    // required: true,
                    message: "Please enter Property Tax!",
                  },
                ]}
                name="Propertytax"
              >
                <Input className="inputStyle" />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>Homeowner's insurance per month </span>
                    <Tooltip title="Home insurance is an insurance policy that covers the costs and damage to your home or any insured property.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    // required: true,
                    message: "Please enter Homeowner insurance!",
                  },
                ]}
                name="Homeownerinsurance"
              >
                <Input className="inputStyle" />
              </Form.Item>
            </Space>
            <Space>
              <Form.Item
                label={
                  <>
                    <span>PMI per month </span>
                    <Tooltip title="The Purchasing Managers Index (PMI) is an indicator of economic health for manufacturing and service sectors.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                name="PMIper"
                rules={[
                  {
                    // required: true,
                    message: "Please enter PMI !",
                  },
                ]}
              >
                <Input className="inputStyle" />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>HOA fees per month </span>
                    <Tooltip title="HOA fees are charges levied on owners of many condominiums, co-ops, and certain neighborhoods. These fees cover the maintenance and upkeep of common areas such as shared hallways, elevators, lobbies, and parking lots.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                name="HOAfees"
                rules={[
                  {
                    // required: true,
                    message: "Please enter HOA Fee!",
                  },
                ]}
              >
                <Input className="inputStyle" />
              </Form.Item>
            </Space>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="button_theme">
                Calculate
              </Button>
            </Form.Item>
          </Form>
        </div>
        {savingData && (
          <div className="MortgageCalculator_Result">
            <div className="gragh">
              <PieChart
                series={[
                  {
                    data: [
                      {
                        id: 0,
                        value: savingData?.principalAndInterest,
                        color: "#FF9671",
                      },
                      {
                        id: 1,
                        value: savingData?.propertyTaxPerMonth,
                        color: "#FCBA39",
                      },
                      {
                        id: 2,
                        value: savingData?.homeownersInsurancePerMonth,
                        color: "#F3ED5E",
                      },
                      {
                        id: 3,
                        value: savingData?.hoaFeesPerMonth,
                        color: "#F67A4E",
                      },
                      {
                        id: 4,
                        value: savingData?.pmiPerMonth,
                        color: "#D2F375",
                      },
                    ],
                  },
                ]}
                width={300}
                height={200}
              />
            </div>
            <div>
              <div className="total_Saving">
                <p className="total_saving_heading">Principal & Interest</p>

                <p className="total_saving_amount">
                  {/* {savingData?.totalMonthlyPayment
                    ? `$${Number(savingData.totalMonthlyPayment).toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}`
                    : "$0.00"} */}
                  {savingData?.principalAndInterest
                    ? `$${Number(
                        savingData.principalAndInterest
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                    : "$0.00"}
                </p>
              </div>
              <div>
                <h5>Total Savings Breakdown</h5>
                <div className="values">
                  <div className="first_color"></div>
                  {/* <div>
                    <p className="vul">Principal & Interest</p>
                    <p className="amt">
                  
                      {savingData?.principalAndInterest
                        ? `$${Number(
                            savingData.principalAndInterest
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : "$0.00"}
                    </p>
                  </div> */}
                  <div>
                    <p className="vul">Monthly Payment</p>
                    <p className="amt">
                      {savingData?.totalMonthlyPayment
                        ? `$${Number(
                            savingData.totalMonthlyPayment
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : "$0.00"}
                      /mo
                    </p>
                  </div>
                  <div className="second_color"></div>
                  <div>
                    <p className="vul">Property Tax</p>
                    <p className="amt">
                      {savingData?.propertyTaxPerMonth
                        ? `$${Number(
                            savingData.propertyTaxPerMonth
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : "$0.00"}
                    </p>
                  </div>
                  <div className="third_color"></div>
                  <div>
                    <p className="vul">Homeowner’s Insurance</p>
                    <p className="amt">
                      {savingData?.homeownersInsurancePerMonth
                        ? `$${Number(
                            savingData.homeownersInsurancePerMonth
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : "$0.00"}
                    </p>
                  </div>
                  <div className="fourth_color"></div>
                  <div>
                    <p className="vul">HOA Fees Per Month</p>
                    <p className="amt">
                      {savingData?.hoaFeesPerMonth
                        ? `$${Number(savingData.hoaFeesPerMonth).toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}`
                        : "$0.00"}
                    </p>
                  </div>
                  <div className="fifth_color"></div>
                  <div>
                    <p className="vul">PMI Per Month</p>
                    <p className="amt">
                      {" "}
                      {savingData?.pmiPerMonth
                        ? `$${Number(savingData.pmiPerMonth).toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}`
                        : "$0.00"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default MortgageCalculator;
